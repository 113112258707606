/**
 * OOI-specific experiments should be listed here.
 */
export const OOI_EXPERIMENTS = {
  /**
   * https://bo.wix.com/petri/experiments/3221548
   */
  FIX_MOBILE_HEADER_STYLING: 'specs.wixBlog.mobileHeaderStyling',
  /**
   *https://bo.wix.com/petri/experiments/3272403
   */
  REMOVE_CATEGORY_TITLE: 'specs.wixBlog.RemoveCategoryTitle',
  /**
   * https://bo.wix.com/guineapig/experiments/3279465
   */
  REMOVE_CATEGORIES_LIVE_SITE_EDITING:
    'specs.wixBlog.RemoveCategoriesLiveSiteEditing',
  /**
   * https://bo.wix.com/petri/experiments/3288198
   */
  FIX_MY_POSTS_USER_ID: 'specs.wixBlog.FixMyPostsUserId',
  /**
   * https://bo.wix.com/guineapig/experiments/3401428
   */
  POST_LIST_WIDGET_USE_ADAPTER: 'specs.wixBlog.PostListWidgetUseAdapter',
  /**
   * https://bo.wix.com/guineapig/experiments/3428202
   */
  FIX_BLURRY_IMAGE: 'specs.wixBlog.FixBlurryImage',
};
